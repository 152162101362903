<template>
  <div class="heading-table">
    <p class="heading-table__title">{{ title }}</p>
    <p class="heading-table__sub">{{ subTitle }}</p>
  </div>
</template>

<script>
export default {
  props: ["title", "subTitle"],
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.heading-table {
  p {
    margin: 0;
    text-align: center;
  }
  &__sub {
    font-style: italic !important;
    font-weight: 400;
    text-align: center;
  }
}
</style>
